import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from './pages/WebIndexPage'
import MagazineList from "./pages/magazine/MagazineList";
import PodcastList from "./pages/podcast/PodcastList";
import AuthorsList from "./pages/author/AuthorsList";
import AuthorDetail from "./pages/author/AuthorsDetailsPage";
import MagazineDetail from "./pages/magazine/MagazineDetails";
import PodcastDetail from "./pages/podcast/PodcastDetail"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/magazine-list" element={<MagazineList />} />
        <Route path="/magazine-details/:article_id/:article_name" element={<MagazineDetail />} />
        <Route path="/podcast-list" element={<PodcastList />} />
        <Route path="/podcast-details/:podcast_name/:podcast_title/:podcast_id" element={<PodcastDetail />} />
        <Route path="/authors-list" element={<AuthorsList />} />
        <Route path="/authors-details/:auth_id/:auth_name" element={<AuthorDetail />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
