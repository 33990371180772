const authorsData = [
  {
    "auth_name": "Alice Johnson",
    "auth_job": "Sports Journalist",
    "auth_city": "New York",
    "auth_img": "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    "auth_name": "Bob Smith",
    "auth_job": "Dance Instructor",
    "auth_city": "Los Angeles",
    "auth_img": "https://images.unsplash.com/photo-1573497019940-1c28c88b4f3e?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    "auth_name": "Charlie Brown",
    "auth_job": "Chef",
    "auth_city": "Paris",
    "auth_img": "https://images.unsplash.com/photo-1605993439219-9d09d2020fa5?q=80&w=1374&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    "auth_name": "Diana Miller",
    "auth_job": "Astrophysicist",
    "auth_city": "Houston",
    "auth_img": "https://images.unsplash.com/photo-1520295187453-cd239786490c?q=80&w=1376&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
  {
    "auth_name": "Ethan Wilson",
    "auth_job": "Tech Innovator",
    "auth_city": "San Francisco",
    "auth_img": "https://images.unsplash.com/photo-1628157588553-5eeea00af15c?q=80&w=1480&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
  },
];

export default authorsData;
