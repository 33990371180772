import { Link } from "react-router-dom";

export default function PodcastListBox({ podcast, index }) {

    const reversedArray = podcast.podcast_episodes.slice().reverse();

    return (
        <ul key={index} className="divide-y divide-black">
            {reversedArray.map((data, i) => (
                <li className="w-full flex items-center relative justify-between max-[490px]:flex-col max-[490px]:items-start max-[490px]:gap-[4dvw] py-[3dvw]" key={i}>
                    <div className="flex items-center gap-[5dvw]">
                        <h6 className="text-black  uppercase text-[1.5dvw] fontSemiBold w-[2dvw] max-[490px]:text-[2dvw]">0{data.podcast_Ep}</h6>
                        <div className="relative pt-[19dvw] w-[19dvw] max-[490px]:min-w-[34dvw] max-[490px]:max-w-[34dvw] max-[490px]:pt-[32dvw]">
                            <img src={data.podcast_img} alt="img_1" className="absolute inset-0 w-full h-full object-cover" />
                            <div className="absolute inset-0 w-full h-full p-[1dvw] max-[490px]:p-[2dvw]">
                                <div className="flex flex-col justify-between h-full">
                                    <div>
                                        <h4 className="text-white fontSemiBold uppercase text-[2.2dvw] max-[490px]:text-[3.8dvw] leading-[100%] max-[490px]:leading-[120%]">{podcast.podcast_name}</h4>
                                        <p className="text-white fontSemiBold uppercase text-[1dvw] max-[490px]:text-[2.2dvw]">Podcast</p>
                                    </div>
                                    <div className="flex items-end justify-between w-full">
                                        <h6 className="text-white  uppercase text-[1.2dvw] max-[490px]:text-[2.4dvw]">Ep <span className="fontSemiBold">{data.podcast_Ep}</span></h6>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-[2.2dvw] h-[2.2dvw] max-[490px]:w-[3.8dvw] max-[490px]:h-[3.8dvw]" viewBox="0 0 52 52" fill="none">
                                            <rect x="0.739746" y="48.2363" width="50.7325" height="3.38217" fill="white" />
                                            <rect x="48.0532" y="0.922363" width="3.38217" height="50.7325" fill="white" />
                                            <rect x="4.80957" y="7.53613" width="3.38217" height="62.5559" transform="rotate(-45 4.80957 7.53613)" fill="white" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h2 className="text-[2dvw] fontSemiBold text-black max-w-[25dvw] max-[490px]:max-w-full max-[490px]:text-[3.4dvw]">{data.podcast_title}</h2>
                    </div>
                    <div className="flex gap-[2.5dvw] max-[490px]:justify-between max-[490px]:w-full">
                        <ul className="flex items-center gap-[2.5dvw] w-full max-[490px]:gap-[3.2dvw]">
                            <li className="flex items-center gap-[0.6dvw] max-[490px]:gap-[1.2dvw]">
                                <h6 className="text-[1dvw] fontBold text-black max-[490px]:text-[2.6dvw]">Date</h6>
                                <p className="text-[1dvw] text-black max-[490px]:text-[2.6dvw]">{data.podcast_date}</p>
                            </li>
                            <li className="flex items-center gap-[0.6dvw] w-fit max-[490px]:gap-[1.2dvw]">
                                <h6 className="text-[1dvw] fontBold text-black max-[490px]:text-[2.6dvw]">Duration</h6>
                                <p className="text-[1dvw] text-black text-nowrap max-[490px]:text-[2.6dvw]">{data.podcast_duration}</p>
                            </li>
                        </ul>
                        <Link to={`/podcast-details/${podcast.podcast_name}/${data.podcast_title}/${data.podcast_id}`} className="text-[1dvw] max-[490px]:justify-end text-black max-[490px]:text-[2.6dvw]  w-full flex items-center fontBold gap-[0.2dvw] max-[490px]:gap-[0.8dvw] group uppercase">Listen
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.2dvw] h-[1.2dvw] max-[490px]:w-[2.4dvw] max-[490px]:h-[2.4dvw] group-hover:-rotate-[128deg]   group-hover:-translate-y-0.5 group-focus:-rotate-[140deg] group-focus:-translate-y-0.5 transition-transform"
                                viewBox="0 0 24 25" fill="none" >
                                <path d="M16.172 11.2873L10.808 5.92328L12.222 4.50928L20 12.2873L12.222 20.0653L10.808 18.6513L16.172 13.2873H4V11.2873H16.172Z" fill="black" />
                            </svg>
                        </Link>
                    </div>
                    <Link to={`/podcast-details/${podcast.podcast_name}/${data.podcast_title}/${data.podcast_id}`} className="absolute inset-0 w-full h-full outline-none"></Link>
                </li>
            ))}
        </ul>
    )
}