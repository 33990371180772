import Layout from './LayoutWrraper'
import Section from './CommonSection'
import { Link } from 'react-router-dom'

export default function DetailPage({ pageTitle, children }) {
    return (
        <Layout>
            <Section>
                <div className='py-[2dvw] flex items-center justify-between'>
                    <Link to="/" className="text-[1.2dvw] text-black  max-[490px]:gap-[0.8dvw] w-fit flex items-center gap-[0.2dvw] max-[490px]:text-[2.4dvw] group uppercase">
                        <svg xmlns="http://www.w3.org/2000/svg" className='w-[1.5dvw] h-[1.5dvw] max-[490px]:w-[2.8dvw] max-[490px]:h-[2.8dvw] transition-transform group-hover:-translate-x-[0.6dvw] group-focus:-translate-x-[0.6dvw]' viewBox="0 0 24 25" fill="none">
                            <path d="M7.828 13.5L13.192 18.864L11.778 20.278L4 12.5L11.778 4.72202L13.192 6.13601L7.828 11.5L20 11.5V13.5L7.828 13.5Z" fill="black" />
                        </svg>
                        Go Back
                    </Link>
                    <h2 className='uppercase text-black fontSemiBold text-[2dvw] max-[490px]:text-[4dvw]'>{pageTitle}</h2>
                </div>
            </Section>
            {children}
        </Layout>
    )
}