import authorsData from "./json/AuthorsData"
import { Link } from "react-router-dom"

export default function AuthorsBox({ author, index }) {

    return (
        <li className={`w-full p-[1.5dvw] max-[490px]:p-[2.5dvw] flex items-center gap-[2dvw] max-[490px]:gap-[4dvw] border-t  border-l border-solid relative max-[490px]:border-l max-[490px]:border-t max-[490px]:border-r  border-black hover:bg-black hover:bg-opacity-10 ${index % 2 === 1 || index === authorsData.length - 1 ? 'border-r' : ''} ${index === authorsData.length - 1 ? 'max-[490px]:border-t-0' : ''}  ${index > 2 ? 'border-b' : ''}`} key={index}>
            <img src={author.auth_img} alt="user_1" className="w-[10dvw] h-[10dvw] max-[490px]:w-[18dvw] max-[490px]:h-[18dvw] object-cover rounded-full" />
            <div className="space-y-[1dvw]">
                <h4 className="text-[2dvw] fontBold text-black max-[490px]:text-[4dvw]">{author.auth_name}</h4>
                <ul className="flex items-center gap-[1.8dvw] max-[490px]:gap-[3.2dvw]">
                    <li className="flex items-center gap-[0.6dvw]">
                        <h6 className="text-[1.2dvw] fontBold text-black max-[490px]:text-[2.4dvw]">Job</h6>
                        <p className="text-[1.2dvw] text-black max-[490px]:text-[2.4dvw]">{author.auth_job}</p>
                    </li>
                    <li className="flex items-center gap-[0.6dvw]">
                        <h6 className="text-[1.2dvw] fontBold text-black max-[490px]:text-[2.4dvw]">City</h6>
                        <p className="text-[1.2dvw] text-black max-[490px]:text-[2.4dvw]">{author.auth_city}</p>
                    </li>
                </ul>
            </div>
            <Link to={`/authors-details/${author.auth_id}/${author.auth_name}`} className="absolute inset-0 w-full h-full focus:bg-black outline-none focus:bg-opacity-10" />
        </li>
    )
}