import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from "../../components/LayoutWrraper";
import Section from "../../components/CommonSection";
import MagazinBox from "./subcomponents/MagazinBox";

export default function MagazineList() {
    const [articleData, setArticleData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://jeetpatel529.github.io/blogData/ArticleData.json');
                setArticleData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <Layout>
            <Section>
                <div className="py-[3dvw]">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-auto" viewBox="0 0 1520 215" fill="none">
                        <path d="M93.591 210.436H130.051L184.598 72.3465V210.436H224.217V4.30633H171.966L111.965 157.038L52.2502 4.30633H0V210.436H39.6183V73.4948L93.591 210.436Z" fill="black" />
                        <path d="M404.145 210.436H450.941L374.001 4.30633H326.344L248.83 210.436H292.755L308.545 166.225H388.642L404.145 210.436ZM349.024 51.9631L376.585 131.487H320.889L349.024 51.9631Z" fill="black" />
                        <path d="M558.162 214.743C623.905 214.743 664.672 166.512 662.088 99.3328H555.865V134.071H615.58C609.838 159.622 590.603 177.708 560.171 177.708C518.831 177.708 496.725 147.277 496.725 107.658C496.725 63.4467 521.989 39.0441 555.291 39.0441C582.851 39.0441 601.799 54.834 608.115 75.7915H659.504C646.011 31.0056 608.689 0 556.439 0C496.151 0 451.939 45.6472 451.939 106.51C451.939 170.244 498.447 214.743 558.162 214.743Z" fill="black" />
                        <path d="M819.359 210.436H866.155L789.215 4.30633H741.558L664.044 210.436H707.968L723.758 166.225H803.856L819.359 210.436ZM764.238 51.9631L791.798 131.487H736.103L764.238 51.9631Z" fill="black" />
                        <path d="M1044.28 210.436V172.828H934.903L1040.84 39.3312V4.30633H882.079V41.915H987.154L881.792 175.986V210.436H1044.28Z" fill="black" />
                        <path d="M1119.66 210.436V4.30633H1076.02V210.436H1119.66Z" fill="black" />
                        <path d="M1296.17 210.436H1338.95V4.30633H1298.76V140.961L1208.9 4.30633H1161.81V210.436H1201.72V66.6047L1296.17 210.436Z" fill="black" />
                        <path d="M1520 172.541H1424.97V123.161H1509.38V85.5526H1424.97V42.2021H1517.99V4.30633H1381.34V210.436H1520V172.541Z" fill="black" />
                    </svg>
                </div>
            </Section>
            <Section>
                <div className="space-y-[5dvw] py-[3dvw]">
                    <div className="flex items-center justify-between w-full">
                        <h6 className="uppercase text-[1.2dvw] fontSemiBold max-[490px]:text-[2.4dvw]">Categories</h6>
                        <ul className="flex gap-[1dvw]">
                            <li>
                                <button type="submit" className=" bg-transparent text-black  px-[1.4dvw] max-[490px]:px-[2dvw] rounded-full text-center py-[0.6dvw] max-[490px]:py-[1dvw] text-[1.2dvw] max-[490px]:text-[2dvw] border border-solid border-black hover:bg-black hover:border-transparent hover:text-white focus:bg-black focus:border-transparent focus:text-white  uppercase">All</button>
                            </li>
                            <li>
                                <button type="submit" className=" bg-transparent text-black  px-[1.4dvw] max-[490px]:px-[2dvw] rounded-full text-center py-[0.6dvw] max-[490px]:py-[1dvw] text-[1.2dvw] max-[490px]:text-[2dvw] border border-solid border-black hover:bg-black hover:border-transparent hover:text-white focus:bg-black focus:border-transparent focus:text-white uppercase">art</button>
                            </li>
                            <li>
                                <button type="submit" className=" bg-transparent text-black  px-[1.4dvw] max-[490px]:px-[2dvw] rounded-full text-center py-[0.6dvw] max-[490px]:py-[1dvw] text-[1.2dvw] max-[490px]:text-[2dvw] border border-solid border-black hover:bg-black hover:border-transparent hover:text-white focus:bg-black focus:border-transparent focus:text-white uppercase">Street Art</button>
                            </li>
                            <li>
                                <button type="submit" className=" bg-transparent text-black  px-[1.4dvw] max-[490px]:px-[2dvw] rounded-full text-center py-[0.6dvw] max-[490px]:py-[1dvw] text-[1.2dvw] max-[490px]:text-[2dvw] border border-solid border-black hover:bg-black hover:border-transparent hover:text-white focus:bg-black focus:border-transparent focus:text-white uppercase">Sculptures</button>
                            </li>
                        </ul>
                    </div>
                    <div className="w-full">
                        <ul className="grid grid-cols-3 w-full max-[620px]:grid-cols-2">
                            {articleData.map((item, index) => (
                                <MagazinBox item={item} index={index} />
                            ))}
                        </ul>
                    </div>
                </div>
            </Section>
        </Layout>
    )
}