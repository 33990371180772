import React, { useState, useEffect } from "react";
import axios from "axios";
import Layout from "../components/LayoutWrraper";
import Section from "../components/CommonSection";
import Article from "../subcomponents/ArticleComponent";
import { Link } from "react-router-dom";
import PodcastBox from "../subcomponents/PodcastBox";
import AuthorsBox from "../subcomponents/AuthorsBox";
import MostPopulatList from "../subcomponents/MostPopularList";

export default function IndexPage() {
    const [articleData, setArticleData] = useState([]);
    const [lastArticle, setLastArticle] = useState([]);
    const [podcastData, setPodcastData] = useState([]);
    const [authorsData, setAuthorsData] = useState([]);



    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post("http://localhost:3001/api/send-email", { email });
            setSubmitted(true);
        } catch (error) {
            console.error("Error sending email:", error);
        }
    };

    useEffect(() => {
        const fetchArticleData = async () => {
            try {
                const response = await axios.get('https://jeetpatel529.github.io/blogData/ArticleData.json');

                const reverseArray = response.data.reverse()

                // const modifiedArray = reverseArray.slice(0, reverseArray.length - 1);

                setArticleData(reverseArray);
                setLastArticle(reverseArray[0]);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchPodCastData = async () => {
            try {
                const response = await axios.get('https://jeetpatel529.github.io/blogData/PodCastData.json');
                setPodcastData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchAuthorData = async () => {
            try {
                const response = await axios.get('https://jeetpatel529.github.io/blogData/AuthorsData.json');
                setAuthorsData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchArticleData();
        fetchPodCastData();
        fetchAuthorData();
    }, []);



    return (
        <Layout>
            <Section>
                <div className="py-[3dvw]">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-full h-auto" viewBox="0 0 1520 216" fill="none">
                        <path d="M160.207 212.622H208.476L129.113 0H79.9553L0 212.622H45.308L61.5952 167.018H144.216L160.207 212.622ZM103.35 49.1577L131.778 131.186H74.3288L103.35 49.1577Z" fill="black" />
                        <path d="M279.39 212.622V137.405H307.523L355.496 212.622H409.095L354.311 130.594C384.517 120.525 402.284 97.7232 402.284 68.9985C402.284 24.875 371.191 0 317.591 0H234.378V212.622H279.39ZM279.39 37.3125H315.518C342.762 37.3125 357.273 47.9732 357.273 68.9985C357.273 89.7276 342.762 100.388 315.518 100.388H279.39V37.3125Z" fill="black" />
                        <path d="M528.688 212.622V40.5699H595.91V0H416.751V40.5699H483.676V212.622H528.688Z" fill="black" />
                        <path d="M759.865 96.5387C746.539 82.0283 740.616 74.0327 740.616 63.0759C740.616 50.9345 749.204 42.3467 760.753 42.3467C772.894 42.3467 781.482 49.75 782.963 63.6681H821.164C820.868 31.0937 796.881 9.47619 760.753 9.47619C724.921 9.47619 698.269 31.9821 698.269 62.7797C698.269 74.9211 701.823 85.5818 709.226 95.9464C686.72 108.68 673.098 127.632 673.098 150.138C673.098 189.82 701.527 215.287 745.947 215.287C765.491 215.287 783.555 207.884 799.546 193.966L817.018 212.622H867.656L823.237 164.649C832.121 148.065 838.043 127.632 838.339 109.865H800.731C800.435 119.341 799.546 128.817 797.769 137.405L759.865 96.5387ZM712.484 147.769C712.484 135.628 719.295 125.856 730.252 120.229L778.521 171.46C770.229 178.271 759.865 181.824 748.908 181.824C724.921 181.824 712.484 166.722 712.484 147.769Z" fill="black" />
                        <path d="M1089.26 212.622V172.052H996.871V0H951.859V212.622H1089.26Z" fill="black" />
                        <path d="M1165.18 212.622V0H1120.17V212.622H1165.18Z" fill="black" />
                        <path d="M1253.67 212.622V131.482H1331.55V92.6889H1253.67V39.0893H1346.06V0H1208.66V212.622H1253.67Z" fill="black" />
                        <path d="M1520 173.533H1421.98V122.598H1509.04V83.805H1421.98V39.0893H1517.93V0H1376.97V212.622H1520V173.533Z" fill="black" />
                    </svg>
                </div>
            </Section>
            <Section>
                <div className="pt-[3dvw] space-y-[2dvw] max-[490px]:flex  max-[490px]:space-y-[2dvw] max-[490px]:flex-col-reverse">
                    <div className="grid grid-cols-2 gap-[2dvw] max-[490px]:grid-cols-1 max-[490px]:mt-[2dvw]">
                        <div>
                            <h2 className="text-black text-[5dvw] fontBold uppercase leading-[110%] tracking-wider max-[490px]:text-[7dvw]">{lastArticle.article_name}</h2>
                        </div>
                        <div className="flex flex-col justify-between pt-[0.2dvw] ">
                            <p className="text-black text-[1.2dvw] max-[490px]:text-[2.6dvw]">{lastArticle.article_description}</p>
                            <div className="w-full justify-between flex items-center max-[490px]:pt-[1.4dvw]">
                                <ul className="flex items-center gap-[1.8dvw] max-[490px]:gap-[2.4dvw]">
                                    <li className="flex items-center gap-[0.6dvw] max-[490px]:gap-[1.2dvw]">
                                        <h6 className="text-[1.2dvw] fontBold text-black max-[490px]:text-[2.2dvw]">Text</h6>
                                        <p className="text-[1.2dvw] text-black max-[490px]:text-[2.2dvw]">{lastArticle.article_owner}</p>
                                    </li>
                                    <li className="flex items-center gap-[0.6dvw] max-[490px]:gap-[1.2dvw]">
                                        <h6 className="text-[1.2dvw] fontBold text-black max-[490px]:text-[2.2dvw]">Date</h6>
                                        <p className="text-[1.2dvw] text-black max-[490px]:text-[2.2dvw]">{lastArticle.article_date}</p>
                                    </li>
                                    <li className="flex items-center gap-[0.6dvw] max-[490px]:gap-[1.2dvw]">
                                        <h6 className="text-[1.2dvw] fontBold text-black max-[490px]:text-[2.2dvw]">Duration</h6>
                                        <p className="text-[1.2dvw] text-black max-[490px]:text-[2.2dvw]">{lastArticle.article_read}</p>
                                    </li>
                                </ul>
                                <span className="border border-solid border-black rounded-full uppercase py-[0.4dvw] max-[490px]:py-[1dvw] max-[490px]:px-[1.8dvw] max-[490px]:text-[2dvw] px-[1dvw] text-[1dvw] hover:bg-black hover:text-white focus:bg-black focus:text-white">{lastArticle.article_tag}</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full pt-[45dvw] relative">
                        <img src={lastArticle.article_img} alt="new_1" className="w-full absolute inset-0 h-full object-cover" />
                    </div>
                </div>
            </Section>
            <Section>
                <div className="py-[3dvw]">
                    <div className="grid grid-cols-3 max-[490px]:grid-cols-1 items-start gap-[4dvw] max-[490px]:gap-[1dvw]">
                        <div className=" col-span-2">
                            <Article list={articleData} limit={5} />
                        </div>
                        <aside className="space-y-[3dvw] max-[490px]:w-full ">

                            <article className="space-y-[1.5dvw] max-[490px]:hidden ">
                                <div>
                                    <p className="text-black text-[1dvw]">Printmagazine</p>
                                    <h4 className="text-black text-[2dvw] fontSemiBold">03/2022</h4>
                                </div>
                                <div className="space-y-[0.5dvw]">
                                    <div className="relative w-full pt-[25dvw]">
                                        <img src="https://images.unsplash.com/photo-1588775161074-a5a92eaa9a37?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="img_1" className="absolute inset-0 w-full h-full object-cover" />
                                        <div className="absolute inset-0 w-full h-full p-[1.5dvw]">
                                            <div className="flex flex-col justify-between h-full">
                                                <div>
                                                    <h4 className="text-white fontSemiBold uppercase text-[4dvw] leading-[100%]">Fyrre</h4>
                                                    <p className="text-white fontSemiBold uppercase text-[1.5dvw]">Magazin</p>
                                                </div>
                                                <div className="flex items-end justify-between w-full">
                                                    <div className="space-y-[0.4dvw]">
                                                        <div className="w-[7dvw] h-[7dvw] rounded-full bg-[#ECC584] text-center p-[0.6dvw] space-y-[0.2dvw] -rotate-12">
                                                            <small className="block text-[0.8dvw] text-white">Exclusive</small>
                                                            <p className="block text-[1dvw] text-center text-white">Jacob Gronberg</p>
                                                            <small className="block text-[0.7dvw] text-white uppercase">Interview</small>
                                                        </div>
                                                        <h6 className="text-white  uppercase text-[1.5dvw] fontSemiBold">03/2022</h6>
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-[3dvw] h-[3dvw]" viewBox="0 0 52 52" fill="none">
                                                        <rect x="0.739746" y="48.2363" width="50.7325" height="3.38217" fill="white" />
                                                        <rect x="48.0532" y="0.922363" width="3.38217" height="50.7325" fill="white" />
                                                        <rect x="4.80957" y="7.53613" width="3.38217" height="62.5559" transform="rotate(-45 4.80957 7.53613)" fill="white" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" className="bg-black text-white w-full max-w-[30%] text-center py-[0.6dvw] text-[1.2dvw] border border-solid border-transparent hover:bg-transparent hover:border-black hover:text-black focus:bg-transparent focus:border-black focus:text-black uppercase">Button</button>
                                </div>
                            </article>


                            <MostPopulatList />

                            <div className="p-[3dvw] bg-[#F8F8F8] space-y-[1.5dvw] max-[490px]:w-full">
                                <div className="space-y-[0.6dvw]">
                                    <h6 className="text-[1.3dvw] fontSemiBold text-black uppercase max-[490px]:text-[3.4dvw]">NewsLetter</h6>
                                    <h4 className="text-[2dvw] fontSemiBold text-black leading-[130%] max-[490px]:text-[6dvw]">Design News to your inbox</h4>
                                </div>
                                <form className="space-y-[0.6dvw] max-[490px]:space-y-[1.5dvw] flex flex-col items-end" onSubmit={handleSubmit} >
                                    <input type="email" className="bg-white w-full px-[1.3dvw] py-[0.4dvw] text-[1.5dvw] max-[490px]:py-[1.5dvw] max-[490px]:text-[2dvw] text-black placeholder:opacity-60" placeholder="Email" value={email}
                                        onChange={(e) => setEmail(e.target.value)} />
                                    <button type="submit" className="bg-black text-white w-full max-[490px]:max-w-full max-[490px]:py-[1.5dvw] max-[490px]:text-[2dvw] max-w-[30%] text-center py-[0.6dvw] text-[1.2dvw] border border-solid border-transparent hover:bg-transparent hover:border-black hover:text-black focus:bg-transparent focus:border-black focus:text-black">Sign up</button>
                                    {submitted && <p>Thank you for signing up!</p>}
                                </form>
                            </div>
                        </aside>
                    </div>
                </div>
            </Section>
            <Section>
                <div className="space-y-[3dvw] py-[4dvw]">
                    <div className="border-t border-solid border-black pt-[2dvw]">
                        <div className="flex items-center justify-between w-full">
                            <h2 className="fontSemiBold text-[7dvw] uppercase leading-[110%] max-[490px]:text-[12dvw]">Podcast</h2>
                            <Link to="/podcast-list" className="flex items-center gap-[0.5dvw] hover:underline focus:underline  group ">
                                <p className="text-black uppercase text-[1dvw] max-[490px]:text-[2.4dvw] fontMedium">All Episodes</p>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.5dvw] max-[490px]:w-[2.4dvw] max-[490px]:h-[2.4dvw] h-[1.5dvw] group-hover:-rotate-[140deg] group-hover:-translate-y-0.5 group-focus:-rotate-[140deg] group-focus:-translate-y-0.5 transition-transform"
                                    viewBox="0 0 24 25" fill="none" >
                                    <path d="M16.172 11.2873L10.808 5.92328L12.222 4.50928L20 12.2873L12.222 20.0653L10.808 18.6513L16.172 13.2873H4V11.2873H16.172Z" fill="black" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="w-full max-[490px]:hidden">
                        {podcastData.map((item, index) => (
                            <PodcastBox podcast={item} index={index} key={index} limit={3} />
                        ))}
                    </div>
                    <div className="w-full max-[490px]:block hidden">
                        {podcastData.map((item, index) => (
                            <PodcastBox podcast={item} index={index} key={index} limit={2} />
                        ))}
                    </div>
                </div>
            </Section>
            <Section>
                <div className="space-y-[3dvw] py-[4dvw]">
                    <div className="border-t border-solid border-black pt-[2dvw]">
                        <div className="flex items-center justify-between w-full">
                            <h2 className="fontSemiBold text-[7dvw] uppercase leading-[110%] max-[490px]:text-[12dvw]">Authors</h2>
                            <Link to="/authors-list" className="flex items-center gap-[0.5dvw] hover:underline focus:underline  group ">
                                <p className="text-black uppercase text-[1dvw] max-[490px]:text-[2.4dvw] fontMedium">All Authors</p>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.5dvw] max-[490px]:w-[2.4dvw] max-[490px]:h-[2.4dvw] h-[1.5dvw] group-hover:-rotate-[140deg] group-hover:-translate-y-0.5 group-focus:-rotate-[140deg] group-focus:-translate-y-0.5 transition-transform"
                                    viewBox="0 0 24 25" fill="none" >
                                    <path d="M16.172 11.2873L10.808 5.92328L12.222 4.50928L20 12.2873L12.222 20.0653L10.808 18.6513L16.172 13.2873H4V11.2873H16.172Z" fill="black" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <ul className="w-full grid grid-cols-2 max-[490px]:grid-cols-1">
                        {authorsData.map((item, index) => (
                            <AuthorsBox author={item} index={index} />
                        ))}
                    </ul>
                </div>
            </Section>
        </Layout >
    )
}