import { Link } from "react-router-dom"
import articleData from "../../../subcomponents/json/ArticleData"

export default function MagazinBox({ item, index }) {
    return (
        <li key={index} className={`p-[2.5dvw] max-[490px]:p-[3dvw] relative space-y-[1.5dvw] border-l border-solid border-t hover:bg-black hover:bg-opacity-10 max-[620px]:border-0 max-[620px]:border-t max-[620px]:border-l transition-colors border-black ${index % 3 === 2 || index === articleData.length - 1 ? 'border-r' : ''} ${index >= articleData.length - 3 ? 'border-b' : ''} ${index >= articleData.length - 2 ? 'max-[620px]:border-b' : ''} ${index % 2 === 1 || index === articleData.length - 1 ? 'max-[620px]:border-r' : ''}`}>
            <div className="flex items-center justify-between w-full">
                <p className="text-black text-[1.2dvw] max-[490px]:text-[2dvw]">{item.article_date}</p>
                <span type="submit" className=" bg-transparent text-black block z-10 relative  px-[1.4dvw] max-[490px]:px-[2dvw] rounded-full text-center py-[0.6dvw] max-[490px]:py-[1dvw] text-[1dvw] max-[490px]:text-[1.6dvw]  border border-solid border-black hover:bg-black hover:border-transparent hover:text-white focus:bg-black focus:border-transparent focus:text-white  uppercase">{item.article_tag}</span>
            </div>
            <div className="relative w-full pt-[25dvw] max-[490px]:pt-[28dvw]">
                <img src={item.article_img} alt={item.article_img} className="absolute inset-0 w-full h-full object-cover" />
            </div>
            <div className="space-y-[2dvw]">
                <div className="space-y-[0.6dvw]">
                    <h4 className="text-[2.2dvw] fontSemiBold max-[490px]:text-[4dvw]">{item.article_name}</h4>
                    <p className="text-[1dvw] max-[490px]:text-[2.6dvw]">{item.article_description}</p>
                </div>
                <ul className="flex items-center gap-[1.8dvw]">
                    <li className="flex items-center gap-[0.6dvw]">
                        <h6 className="text-[1dvw] fontBold text-black max-[490px]:text-[2.2dvw]">Text</h6>
                        <p className="text-[1dvw] text-black max-[490px]:text-[2.2dvw]">{item.article_owner}</p>
                    </li>
                    <li className="flex items-center gap-[0.6dvw]">
                        <h6 className="text-[1dvw] fontBold text-black max-[490px]:text-[2.2dvw]">Duration</h6>
                        <p className="text-[1dvw] text-black max-[490px]:text-[2.2dvw]">{item.article_read}</p>
                    </li>
                </ul>
            </div>
            <div className="absolute right-[2dvw] top-[1dvw] focus-within:z-20">
                <Link to={`/magazine-details/${item.article_id}/${item.article_name}`} className="bg-black text-white block  opacity-0  px-[1.4dvw] rounded-full text-center py-[0.6dvw] text-[1dvw] border border-solid  border-transparent  focus:opacity-100  uppercase">read Magazine</Link>
            </div>
            <div className="absolute inset-0 w-full h-full">
                <Link to={`/magazine-details/${item.article_id}/${item.article_name}`} className="block w-full h-full outline-none"></Link>
            </div>
        </li>
    )
}