import { Link } from "react-router-dom";

export default function Article({ list, limit }) {

    const array = list;
    const articleArray = array.slice(0, limit);

    return (
        <ul className="divide-y divide-black">
            {articleArray.map((item, index) => (
                <li key={index} className="flex items-stretch gap-[2dvw] py-[2.5dvw] relative hover:bg-black hover:bg-opacity-10 group">
                    <div className="absolute right-[1dvw] top-[2dvw] focus-within:z-20">
                        <Link to={`/magazine-details/${item.article_id}/${item.article_name}`} className="bg-black text-white  opacity-0  px-[1.4dvw] rounded-full text-center py-[0.6dvw] text-[1dvw] border border-solid  border-transparent  focus:opacity-100 group-hover:opacity-100  uppercase">read Magazine</Link>
                    </div>
                    <div className="w-[36dvw] max-[490px]:w-[40dvw] relative">
                        <img src={item.article_img} alt={item.article_img} className="absolute inset-0 w-full h-full object-cover" />
                    </div>
                    <div className="flex flex-col justify-between pt-[0.2dvw] gap-[4dvw] w-full">
                        <div className="space-y-[0.1dvw]">
                            <h2 className="text-[2dvw] fontSemiBold max-[490px]:text-[5.5dvw]">{item.article_name}</h2>
                            <p className="text-black text-[1.2dvw] max-[490px]:text-[2.4dvw]">{item.article_description}</p>
                        </div>
                        <div className="w-full justify-between flex items-center flex-wrap gap-[.5dvw]">
                            <ul className="flex items-center gap-[1.8dvw] max-[490px]:gap-[2.6dvw]">
                                <li className="flex items-center gap-[0.6dvw] ">
                                    <h6 className="text-[1.2dvw] max-[490px]:text-[1.8dvw] fontBold text-black">Text</h6>
                                    <p className="text-[1.2dvw]  max-[490px]:text-[1.8dvw] text-black">{item.article_owner}</p>
                                </li>
                                <li className="flex items-center gap-[0.6dvw]">
                                    <h6 className="text-[1.2dvw] max-[490px]:text-[1.8dvw] fontBold text-black">Date</h6>
                                    <p className="text-[1.2dvw] max-[490px]:text-[1.8dvw] text-black">{item.article_date}</p>
                                </li>
                                <li className="flex items-center gap-[0.6dvw]">
                                    <h6 className="text-[1.2dvw] max-[490px]:text-[1.8dvw] fontBold text-black">Read</h6>
                                    <p className="text-[1.2dvw] max-[490px]:text-[1.8dvw] text-black">{item.article_read}</p>
                                </li>
                            </ul>
                            <span className="border border-solid border-black ml-auto rounded-full uppercase py-[0.4dvw] px-[1dvw] max-[490px]:text-[2dvw] max-[490px]:px-[2dvw]  text-[1dvw] hover:bg-black hover:text-white focus:bg-black focus:text-white">{item.article_tag}</span>
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
}
