export default function MostPopulatList() {

    const popularArticle = [
        {
            "id": 1,
            "article_name": "The Thrill of Victory",
            "article_author_name": "Diana Miller",
        },
        {
            "id": 1,
            "article_name": "Journey into History",
            "article_author_name": "Bob Smith",
        },
        {
            "id": 1,
            "article_name": "The Dance of Light",
            "article_author_name": "Ethan Wilson",
        }
    ]

    return (
        <div className="space-y-[0.5dvw] max-[490px]:hidden">
            <h6 className="text-black fontSemiBold text-[1.3dvw] uppercase">Most Popular</h6>
            <ul className="divide-y divide-black">
                {
                    popularArticle.map((item, index) => (
                        <li className="flex items-start gap-[1.3dvw] w-full pb-[1.5dvw] pt-[1.5dvw]">
                            <h6 className="text-[1.4dvw] text-black fontBold">0{index + 1}.</h6>
                            <div className="space-y-[0.3dvw]">
                                <h4 className="text-[1.6dvw] text-black fontSemiBold">{item.article_name}</h4>
                                <h6 className="text-[1dvw] text-black"> <span className="fontSemiBold mr-[0.7dvw]">Text</span> {item.article_author_name} </h6>
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}