import { Link } from "react-router-dom"

export default function AuthorsListBox({ author, index }) {
    return (
        <li className="w-full flex items-center justify-between pt-[3dvw] max-[490px]:flex-col max-[490px]:items-start max-[490px]:gap-[2dvw]" key={index}>
            <div className="flex items-center gap-[5dvw] ">
                <div className="relative w-[12dvw] h-[12dvw] max-[490px]:w-[18dvw] max-[490px]:h-[18dvw]">
                    <img src={author.auth_img} alt="img_1" className="absolute inset-0 w-full h-full object-cover rounded-full " />
                </div>
                <h2 className="text-[2dvw] fontSemiBold text-black max-w-[25dvw] max-[490px]:max-w-full max-[490px]:text-[4dvw]">{author.auth_name}</h2>
            </div>
            <div className="flex gap-[2.5dvw] max-[490px]:w-full max-[490px]:justify-between max-[490px]:items-center">
                <ul className="flex items-center gap-[2.5dvw] w-full max-[490px]:gap-[4dvw]">
                    <li className="flex items-center gap-[0.6dvw] max-[490px]:gap-[2dvw]">
                        <h6 className="text-[1dvw] fontBold text-black max-[490px]:text-[2.8dvw]">Job</h6>
                        <p className="text-[1dvw] text-black text-nowrap max-[490px]:text-[2.8dvw]">{author.auth_job}</p>
                    </li>
                    <li className="flex items-center gap-[0.6dvw] w-fit max-[490px]:gap-[2dvw]">
                        <h6 className="text-[1dvw] fontBold text-black max-[490px]:text-[2.8dvw]">City</h6>
                        <p className="text-[1dvw] text-black text-nowrap max-[490px]:text-[2.8dvw]">{author.auth_city}</p>
                    </li>
                </ul>
                <Link to={`/authors-details/${author.auth_id}/${author.auth_name}`} className="text-[1dvw] text-black max-[490px]:text-[2.4dvw] max-[490px]:justify-end  w-full flex items-center fontBold gap-[0.2dvw] group uppercase">About
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-[1.2dvw] h-[1.2dvw] max-[490px]:w-[2.2dvw] max-[490px]:h-[2.2dvw] group-hover:-rotate-[128deg]   group-hover:-translate-y-0.5 group-focus:-rotate-[140deg] group-focus:-translate-y-0.5 transition-transform"
                        viewBox="0 0 24 25" fill="none" >
                        <path d="M16.172 11.2873L10.808 5.92328L12.222 4.50928L20 12.2873L12.222 20.0653L10.808 18.6513L16.172 13.2873H4V11.2873H16.172Z" fill="black" />
                    </svg>
                </Link>
            </div>
        </li>
    )
}