import { Link } from "react-router-dom";

export default function PodcastBox({ podcast, index, limit }) {
    const array = podcast.podcast_episodes;
    const reversedArray = array.slice().reverse();
    const episodesToDisplay = reversedArray.slice(0, limit);

    return (
        <ul key={index} className="grid grid-cols-3 max-[490px]:grid-cols-2">
            {episodesToDisplay.map((data, i) => (
                <li className={`p-[2dvw] space-y-[1.5dvw] border-l border-t border-solid relative  group hover:bg-black hover:bg-opacity-10 border-black ${i % 3 === 2 || i === episodesToDisplay.length - 1 ? 'border-r' : ''} ${i >= episodesToDisplay.length - 3 ? 'border-b' : ''}`} key={i}>
                    <div className="relative w-full pt-[25dvw] max-[490px]:pt-[30dvw]">
                        <img src={data.podcast_img} alt="img_1" className="absolute inset-0 w-full h-full object-cover" />
                        <div className="absolute inset-0 w-full h-full p-[1.5dvw]">
                            <div className="flex flex-col justify-between h-full">
                                <div>
                                    <h4 className="text-white fontSemiBold uppercase text-[3dvw] max-[490px]:text-[4dvw] leading-[100%]">{podcast.podcast_name}</h4>
                                    <p className="text-white fontSemiBold uppercase text-[1.5dvw] max-[490px]:text-[2dvw]">Podcast</p>
                                </div>
                                <div className="flex items-end justify-between w-full">
                                    <h6 className="text-white  uppercase text-[1.5dvw] max-[490px]:text-[2dvw]">Ep <span className="fontSemiBold">{data.podcast_Ep}</span></h6>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-[3dvw] h-[3dvw] max-[490px]:w-[4dvw] max-[490px]:h-[4dvw]" viewBox="0 0 52 52" fill="none">
                                        <rect x="0.739746" y="48.2363" width="50.7325" height="3.38217" fill="white" />
                                        <rect x="48.0532" y="0.922363" width="3.38217" height="50.7325" fill="white" />
                                        <rect x="4.80957" y="7.53613" width="3.38217" height="62.5559" transform="rotate(-45 4.80957 7.53613)" fill="white" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-[2dvw]">
                        <Link to={`/podcast-details/${podcast.podcast_name}/${data.podcast_title}/${data.podcast_id}`} className="text-black text-[2dvw] fontSemiBold focus:underline max-[490px]:text-[4dvw] group-hover:underline">{data.podcast_title}</Link>
                        <ul className="flex items-center gap-[1.8dvw]">
                            <li className="flex items-center gap-[0.6dvw] max-[490px]:gap-[1.4dvw]">
                                <h6 className="text-[1dvw] max-[490px]:text-[2.4dvw] fontBold text-black">Date</h6>
                                <p className="text-[1dvw] max-[490px]:text-[2.4dvw] text-black">{data.podcast_date}</p>
                            </li>
                            <li className="flex items-center gap-[0.6dvw]">
                                <h6 className="text-[1dvw] max-[490px]:text-[2.4dvw] fontBold text-black">Duration</h6>
                                <p className="text-[1dvw] max-[490px]:text-[2.4dvw] text-black">{data.podcast_duration}</p>
                            </li>
                        </ul>
                    </div>
                    <Link to={`/podcast-details/${podcast.podcast_name}/${data.podcast_title}/${data.podcast_id}`} className="absolute inset-0 outline-none w-full h-full"></Link>
                </li>
            ))}
        </ul>
    )
}